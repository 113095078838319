<template>
  <v-tooltip left v-if="hasValue" :open-delay="1000">
    <template #activator="{on}">
      <v-chip
          class="locale-price-badge"
          outlined
          color="grey lighten-1"
          text-color="grey lighten-1"
          small
          v-bind="$attrs"
          :class="badgeClass"
          v-on="on">
        {{ $n(price, 'localeCurrency') }}
      </v-chip>
    </template>
    <span>{{ $t('global.localeCurrencyExplained') }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'LocalePrice',
  inheritAttrs: false,
  props: {
    price: {
      type: [String, Number],
      default: 0,
    },
    badgeClass: { // When upgraded to vue3 this will no longer be needed
      type: String,
    },
  },
  computed: {
    hasValue() {
      return this.price !== null && this.price !== undefined;
    },
  },
};
</script>
