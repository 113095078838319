<template>
  <v-btn v-bind="$attrs" @click="handleReOrderClick">{{ $t('orders.actions.reorder') }}</v-btn>
</template>

<script>
import { EventBus } from '@/application/EventBus.js';
import { reorder } from '@/modules/orders/api/order.js';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ReOrderButton',
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('settings', { storeId: 'getCurrentStoreId' }),
  },
  methods: {
    ...mapActions('shoppingCart', ['fetchAmountInCart']),
    handleReOrderClick() {
      EventBus.$emit('confirm', {
        title: this.$t('orders.reOrder.confirmationTitle'),
        body: this.$t('orders.reOrder.confirmationBody'),
        confirmCallback: async () => {
          await reorder(this.orderId);
          await this.fetchAmountInCart(this.storeId);
          EventBus.$emit('snackbar', {
            color: 'success',
            text: this.$t('orders.reOrder.success'),
          });
          this.$emit('change');
        },
      });
    },
  },
};
</script>
