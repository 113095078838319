<template>
  <k-crud-layout :with-search="false">
    <template #header>
      <router-link :to="{name: 'orders.index'}" class="secondary--text">{{ $t('orders.pageTitle') }}</router-link>
      / {{ $t('orders.details.pageTitle', [orderId]) }}
    </template>
    <template #flow-actions>
      <DownloadOrderButton
        outlined
        tile
        :order-id="parseInt(orderId)"
        :dealer-code="orderDetails.store.dealerCode"
        :dealer-code-extension="orderDetails.store.branchExtension"
        :dealer-name="orderDetails.store.name"
        class="mr-2"
      />
      <template v-if="isApprover || isAdmin">
        <ReOrderButton outlined
                       tile
                       :order-id="parseInt(orderId)"
                       v-if="orderDetails.status[0] && orderDetails.status[0].id === orderStatus.REJECTED"
                       @change="$emit('change')"/>
        <DropdownButton
          v-if="canSetToApproved || canCancelOrder || canSetToOnHold"
          outlined
        >
          {{ orderDetails.status[0].name }}
          <template #list>
            <v-list>
              <v-list-item
                v-if="canCancelOrder"
                @click="cancelOrder"
              >
                {{ $t('orders.actions.cancel') }}
              </v-list-item>
              <v-list-item
                v-if="canSetToApproved"
                @click="toApproved"
              >
                {{ $t('orders.actions.approved') }}
              </v-list-item>
              <v-list-item
                v-if="canSetToOnHold"
                @click="toOnHold"
              >
                {{ $t('orders.actions.onHold') }}
              </v-list-item>
            </v-list>
          </template>
        </DropdownButton>
        <v-btn class="mx-2" outlined tile @click="isCommentsSidebarOpen = true">
          <v-badge class="mr-4" overlap color="yellow" bordered dark v-if="orderDetails.commentCount">
            <v-icon>$comments</v-icon>
            <template #badge>
              <span class="black--text">{{ orderDetails.commentCount }}</span>
            </template>
          </v-badge>
          {{ $t('orders.details.comments') }}
        </v-btn>
        <OrderCommentsSideBar v-model="isCommentsSidebarOpen"
                              :able-to-place-comments="extendedDetails"
                              :order-id="$route.params.orderId"/>
      </template>
    </template>

    <template #sheet-prepend v-if="orderDetails.isLoaded">
      <CustomOrderDialog :custom-order-line="customOrderLineDialog.customOrderLine"
                         v-model="customOrderLineDialog.activator"/>
      <v-row justify="end" align-content="center">
        <v-col cols="auto">
          <PriceBox :title="$t('shoppingCart.total')"
                    :local-amount="orderDetails.localAmount"
                    :total-amount="totalAmount"/>
        </v-col>
      </v-row>
    </template>

    <template #main-prepend v-if="orderDetails.isLoaded">
      <v-fade-transition>
        <k-display-group language-prefix="orders.display">
          <v-row>
            <v-col :md="isAdmin ? 3 : 4" sm="12" v-if="extendedDetails">
              <v-sheet class="pa-4 fill-height">
                <KSubtitle class="pa-0">{{ $t('orders.details.storeInfo') }}</KSubtitle>
                <v-row class="mt-2" no-gutters>
                  <v-col cols="6">
                    <k-text-display class="mb-3" field="storeName">
                      {{ orderDetails.store.name }}
                    </k-text-display>
                    <k-text-display class="mb-3" field="phoneNumber">
                      {{ orderDetails.store.phoneNumber }}
                    </k-text-display>
                    <k-text-display class="mb-3" field="dealerLevel">
                      {{ orderDetails.store.dealerLevel }}
                    </k-text-display>
                  </v-col>
                  <v-col cols="6">
                    <k-text-display class="mb-3" field="branchExtension">
                      {{ orderDetails.store.branchExtension }}
                    </k-text-display>
                    <k-text-display class="mb-3" field="email">
                      {{ orderDetails.store.email }}
                    </k-text-display>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col :md="isAdmin ? 5 : 4" sm="12">
              <v-sheet class="pa-4 fill-height">
                <KSubtitle class="pa-0">{{ $t('orders.details.addressData') }}</KSubtitle>
                <v-row class="mt-2" no-gutters>
                  <v-col cols="12" :md="isAdmin ? 4 : 6">
                    <v-label>{{ $t('orders.details.showroomLocation') }}</v-label>
                    <div>{{ orderDetails.store.showroomAddress.addressLine1 }}</div>
                    <div v-if="orderDetails.store.showroomAddress.addressLine2">
                      {{ orderDetails.store.showroomAddress.addressLine2 }}
                    </div>
                    <div>{{ orderDetails.store.showroomAddress.city }}</div>
                    <div>{{ orderDetails.store.showroomAddress.zipCode }}</div>
                    <div class="mb-4">{{ $t(`countries.${orderDetails.store.showroomAddress.countryId}`) }}</div>
                  </v-col>
                  <v-col cols="12" :md="isAdmin ? 4 : 6">
                    <v-label>{{ $t('orders.details.shippingAddress') }}</v-label>
                    <div>{{ orderDetails.store.shippingAddress.addressLine1 }}</div>
                    <div v-if="orderDetails.store.shippingAddress.addressLine2">
                      {{ orderDetails.store.shippingAddress.addressLine2 }}
                    </div>
                    <div>{{ orderDetails.store.shippingAddress.city }}</div>
                    <div>{{ orderDetails.store.shippingAddress.zipCode }}</div>
                    <div>{{ $t(`countries.${orderDetails.store.shippingAddress.countryId}`) }}</div>
                  </v-col>
                  <v-col v-if="isAdmin" cols="12" md="4">
                    <v-label>{{ $t('orders.details.billingAddress') }}</v-label>
                    <div>{{ orderDetails.store.billingAddress.addressLine1 }}</div>
                    <div v-if="orderDetails.store.billingAddress.addressLine2">
                      {{ orderDetails.store.billingAddress.addressLine2 }}
                    </div>
                    <div>{{ orderDetails.store.billingAddress.city }}</div>
                    <div>{{ orderDetails.store.billingAddress.zipCode }}</div>
                    <div>{{ $t(`countries.${orderDetails.store.billingAddress.countryId}`) }}</div>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <k-text-display width="20px" horizontal label="T">
                      {{ orderDetails.store.phoneNumber }}
                    </k-text-display>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="$vuetify.breakpoint.smAndUp">
                    <k-text-display width="100px" horizontal field="dealerCode" class="mt-1">
                      {{ orderDetails.store.dealerCode }}
                    </k-text-display>
                  </v-col>
                  <v-col cols="12">
                    <k-text-display width="20px" horizontal label="E">
                      {{ orderDetails.store.email }}
                    </k-text-display>
                  </v-col>
                  <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
                    <k-text-display width="100px" horizontal field="dealerCode">
                      {{ orderDetails.store.dealerCode }}
                    </k-text-display>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col md="4" sm="12" v-if="extendedDetails">
              <v-sheet class="pa-4 fill-height">
                <KSubtitle class="pa-0">{{ $t('orders.details.approvers') }}</KSubtitle>
                <v-row class="mt-2" no-gutters>
                  <v-col cols="12" lg="4" order="first" order-md="last">
                    <v-label>{{ $t('') }}</v-label>
                    <k-text-display width="20px" field="orderStatus">
                      {{ orderDetails.status[0].name }}
                    </k-text-display>
                  </v-col>
                  <v-col cols="12" lg="8">
                    <v-list class="pa-0" dense>
                      <v-label>{{ $t('orders.details.approvals') }}</v-label>
                      <v-list-item class="pa-0" v-for="approver in approvers" :key="approver.id">
                        <v-list-item-icon>
                          <v-icon :color="approvalIconColor(approver.isApproved)">
                            {{ approvalIcon(approver.isApproved) }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>{{ approver.user.userName }}
                          <span class="approver-created-at">{{ approver.createdAt }}</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </k-display-group>
      </v-fade-transition>
    </template>

    <template #view.list>
      <v-data-table :headers="crudHeaders"
                    :items="detailsOrderLines"
                    :loading="isPending"
                    :footer-props="{itemsPerPageOptions: [5,10,15,25],
                   itemsPerPageText: $t('global.$vuetify.dataFooter.itemsPerPageText'),
                   pageText: $t('global.$vuetify.dataFooter.pageText')}">
        <template #item="{item}">
          <tr @click="handleRowClick(item)" :class="item.type ? 'cursor-pointer' : ''">
            <td rowspan="2">
              <VImg v-if="item.type"
                    :src="`${item.image}&type=thumbnail`"
                    :lazy-src="`${item.image}&type=lazy`"
                    height="100px"
                    max-width="100px"
                    contain/>
              <VImg v-else
                    :src="`${item.image}&type=thumbnail`"
                    :lazy-src="`${item.image}&type=lazy`"
                    height="100px"
                    max-width="100px"
                    contain/>
            </td>
            <td style="border-bottom: 0;">
              <p>{{ item.name || item.title }}</p>
              <template v-if="item.files">
                <v-row no-gutters>
                  <!--                      @TODO why is this a v-alert?, why is everything in one file? this should be refactored!-->
                  <v-alert v-for="(file, index) in item.files"
                           :key="file.id"
                           height="50px"
                           width="50px"
                           outlined
                           tile
                           color="secondary"
                           @click.stop="downloadFile(file)"
                           :class="{'ml-3' : index > 0}"
                           class="pa-0 cursor-pointer">
                    <v-responsive :aspect-ratio="1"
                                  max-height="50px"
                                  max-width="50px"
                                  class="justify-center align-center text-center">
                      <VImg :src="`${file.url}&type=thumbnail`"
                            :aspect-ratio="1"
                            min-height="35px"
                            min-width="35px"
                            :lazy-src="`${file.url}&type=lazy`"
                            v-if="typeIsImage(file.type)"/>
                      <div v-else>
                        <v-icon large>{{ getFileTypeIcon(file.type) }}</v-icon>
                      </div>
                    </v-responsive>
                    <div class="text-truncate" style="width:50px" :title="file.name">{{ file.name }}</div>
                  </v-alert>
                </v-row>
              </template>
            </td>
            <td style="border-bottom: 0;">
              {{ item.sku || (item.type ? item.itemNumber || 'N/A' : '') }}
            </td>

            <td style="border-bottom: 0">
              {{item.quantity}}
            </td>
            <td style="text-align: right; width: 1%; white-space: nowrap; border-bottom: 0">
              <div class="mb-4">
                <LocalePrice :price="item.localPrice" badge-class="mr-2"/>
                {{ $n(item.price, 'currency') }}
              </div>
            </td>

            <td rowspan="2"
                style="text-align: right; width: 1%; white-space: nowrap; position: relative; border-bottom: 0">
              <div class="mb-4">
                <LocalePrice :price="item.localLineTotal" badge-class="mr-2"/>
                {{ $n(item.lineTotal, 'currency') }}
              </div>

              <template v-if="hasAddOns(item)">
                <div>{{ $t('orders.details.addons') }}:</div>
                <div class="pl-8">
                  <LocalePrice :price="item.addOns.localTotalPrice" badge-class="mr-2"/>
                  <span>{{ $n(item.addOns.totalPrice, 'currency') }}</span>
                </div>
              </template>
              <div class="custom-top-border"></div>
            </td>
            <td rowspan="2" style="width: 1%">
              <div class="crud-actions">
                <v-btn :disabled="isPendingRemove.includes(item.id)"
                       color="text--secondary"
                       icon
                       v-if="!item.type && !!$listeners.remove"
                       @click.stop="$emit('remove', item)">
                  <v-icon>$delete</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>

          <tr class="order-addons">
            <td colspan="4" style="border-top: 0;">
              <div v-for="product in item.addOns.products" :key="product.id" class="d-inline ma-3 text-center">
                <v-badge :value="product.quantity" color="black" :content="product.quantity">
                  <VImg :src="`${product.image}&type=thumbnail`"
                        :lazy-src="`${product.image}&type=lazy`"
                        height="100px"
                        max-width="100px"
                        contain/>
                  {{ product.sku }}
                </v-badge>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </k-crud-layout>
</template>

<script lang="js">
import { OrderDetail } from '@/application/models/Order.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';
import OrderCommentsSideBar from '@/modules/orders/components/OrderCommentsSideBar.vue';
import { mapGetters } from 'vuex';
import ReOrderButton from '@/modules/orders/components/ReOrderButton.vue';
import orderStatus from '@/application/enums/orderStatus.js';
import LocalePrice from '@/components/LocalePrice.vue';
import DownloadOrderButton from '@/modules/orders/components/DownloadOrderButton.vue';
import { getFileTypeIcon, typeIsImage } from '@/application/util/displayFile.js';
import downloadFile from '@/application/util/downloadFile.js';
import { get } from '@/application/api/implementations/app';
import CustomOrderDialog from '@/modules/orders/components/CustomOrderDialog.vue';
import PriceBox from '@/components/PriceBox.vue';
import DropdownButton from '@/components/DropdownButton.vue';
import { EventBus } from '@/application/EventBus.js';
import { cancel, setToOnHold, setToApproved } from '@/modules/orders/api/order.js';

export default {
  name: 'DetailLayout',
  components: {
    DropdownButton,
    DownloadOrderButton,
    PriceBox,
    CustomOrderDialog,
    LocalePrice,
    ReOrderButton,
    OrderCommentsSideBar,
    KDisplayGroup,
    KTextDisplay,
    KSubtitle,
    KCrudLayout,
  },
  data() {
    return {
      isCommentsSidebarOpen: false,
      customOrderLineDialog: {
        customOrderLine: null,
        activator: false,
      },
    };
  },
  props: {
    crudHeaders: {
      type: Array,
      required: true,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
    orderDetails: {
      type: OrderDetail,
      required: true,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
    isPendingSubmit: {
      type: Boolean,
      default: false,
    },
    isPendingRemove: {
      type: Array,
      default: () => [],
    },
    extendedDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('authorisation', ['isApprover']),
    ...mapGetters('authorisation', ['isAdmin']),
    totalAmount() {
      return this.orderDetails?.amount || 0;
    },
    approvers() {
      return this.orderDetails.approvers;
    },
    detailsOrderLines() {
      return (this.orderDetails.orderLines).concat(this.orderDetails.customOrderLines);
    },
    orderStatus: () => orderStatus,
    canCancelOrder() {
      if ([orderStatus.APPROVED].includes(this.orderDetails.status[0]?.id) && this.isAdmin) {
        return true;
      }

      if ([orderStatus.ON_HOLD].includes(this.orderDetails.status[0]?.id) && this.isAdmin) {
        return true;
      }

      return false;
    },
    canSetToOnHold() {
      if ([orderStatus.APPROVED].includes(this.orderDetails.status[0]?.id) && this.isAdmin) {
        return true;
      }

      return false;
    },
    canSetToApproved() {
      if ([orderStatus.ON_HOLD].includes(this.orderDetails.status[0]?.id) && this.isAdmin) {
        return true;
      }

      return false;
    },
  },
  methods: {
    typeIsImage,
    getFileTypeIcon,
    approvalIcon(isApproved) {
      if (isApproved === null) return '$unknown';
      if (isApproved === true) return '$approved';
      return '$denied';
    },
    approvalIconColor(isApproved) {
      if (isApproved === null) return 'gray';
      if (isApproved === true) return 'green';
      return 'red';
    },
    hasAddOns(item) {
      return item.addOns?.products?.length > 0;
    },
    downloadFile(file) {
      downloadFile(get(file.url, { responseType: 'blob' }), file.name);
    },
    handleRowClick(orderLine) {
      if (!orderLine.type) return; //@TODO this is the weird check if an orderline is custom, this should be refactored

      this.customOrderLineDialog.customOrderLine = orderLine;
      this.customOrderLineDialog.activator = true;
    },
    async toOnHold() {
      await setToOnHold(this.orderId);
      this.$emit('change');
    },
    async toApproved() {
      await setToApproved(this.orderId);
      this.$emit('change');
    },
    cancelOrder() {
      EventBus.$emit('confirm', {
        title: this.$t('orders.cancelOrder.confirmationTitle'),
        body: this.$t('orders.cancelOrder.confirmationBody'),
        confirmCallback: async () => {
          await cancel(parseInt(this.orderId));
          EventBus.$emit('snackbar', {
            color: 'success',
            text: this.$t('orders.cancelOrder.success'),
          });
          this.$emit('change');
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep {

  .v-data-table-header > tr > th {
    white-space: nowrap;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    vertical-align: top;
    padding-top: 8px
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: inherit;
  }

  .approver-created-at {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75em;
    display: contents;
  }

  tbody tr:not(:first-child) td .custom-top-border {
    position: absolute;
    top: -1px;
    height: 1px;
    background: rgba(0, 0, 0, 0.12);
    left: -1px;
    right: -1px;
  }
}

</style>
