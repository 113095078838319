<template>

  <div class="d-flex box--total">
    <div class="d-inline-flex align-self-center pl-6 pr-8">
      <div class="mt-1 mr-4 font-weight-medium text-h3" v-if="title">{{ title }}:</div>
      <i18n-n :tag="false"
              :value="totalAmount"
              :format="{ key: 'currency' }"
              class="d-inline-flex">
        <template #currency="{currency}">
          <div class="text-h1 font-weight-medium">{{ currency }}</div>
        </template>
        <template #group="{group}">
          <div class="text-h1 font-weight-medium">{{ group }}</div>
        </template>
        <template #integer="{integer}">
          <div class="text-h1 font-weight-medium">{{ integer }}</div>
        </template>
        <template #decimal="{decimal}">
          <div class="text-h1 font-weight-medium">{{ decimal }}</div>
        </template>
        <template #fraction="{fraction}">
          <div class="text-h3 font-weight-medium mt-1">{{ fraction }}</div>
        </template>
      </i18n-n>
      <LocalePrice :price="localAmount"/>
    </div>
  </div>
</template>

<script>
import LocalePrice from '@/components/LocalePrice.vue';
export default {
  name: 'PriceBox',
  components: { LocalePrice },
  props: {
    totalAmount: {
      type: Number,
      required: true,
    },
    localAmount: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.box--total {
  min-width:    240px;
  height:       80px;
  box-sizing:   border-box;
  border:       1px solid rgba(0, 0, 0, 0.25);
  margin-right: 24px;
  margin-top:   24px;
  position:     relative;

  .locale-price-badge {
    position: absolute;
    z-index:  1;
    bottom:   -12px;

    &.v-chip.v-chip--outlined.v-chip.v-chip {
      background: white !important;
    }
  }
}
</style>
