<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" transition="dialog-top-transition" max-width="1000" persistent scrollable>
    <v-card>
      <v-card-actions>
        <VSpacer/>
        <k-btn color="secondary" outlined @click="$emit('input', false)">{{ $t('actions.close') }}</k-btn>
      </v-card-actions>
      <v-card-title>
        <h3 class="text-h3 text-uppercase">{{ $t('orders.customOrderLineTitle') }}</h3>
      </v-card-title>
      <v-card-text v-if="customOrderLine">
        <VDivider class="mt-0 mb-6"/>
        <k-display-group language-prefix="orders.details.customOrderLine">
          <v-row>
            <v-col>
              <k-text-display field="title">{{ customOrderLine.title }}</k-text-display>
              <k-text-display field="description">{{ customOrderLine.description }}</k-text-display>
              <k-text-display field="itemNumber">{{ customOrderLine.itemNumber }}</k-text-display>
              <k-text-display field="quantity">{{ customOrderLine.quantity }}</k-text-display>
            </v-col>
            <v-col>
              <v-list>
                <v-list-item three-line v-for="file in customOrderLine.files" :key="file.id">
                  <v-list-item-content>
                    <v-list-item-title>{{ file.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ file.createdAt }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="downloadFile(file)">
                      <v-icon>$visible</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <PriceBox :total-amount="customOrderLine.price"
                      :local-amount="customOrderLine.localPrice"
                      :title="$t('shoppingCart.total')"/>
          </v-row>
        </k-display-group>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import KBtn from '@/components/KButton.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import downloadFile from '@/application/util/downloadFile.js';
import { get } from '@/application/api/implementations/app';
import PriceBox from '@/components/PriceBox.vue';

export default {
  name: 'CustomOrderDialog',
  components: {
    PriceBox,
    KTextDisplay,
    KDisplayGroup,
    KBtn,
  },
  props: {
    customOrderLine: {
      type: Object,
    },
  },
  methods: {
    downloadFile(file) {
      downloadFile(get(file.url, { responseType: 'blob' }), file.name);
    },
  },
};
</script>

<style scoped>
.v-list-item.v-list-item--three-line.theme--light:nth-child(2n) {
  background: rgba(0, 0, 0, 0.05);
}
</style>
